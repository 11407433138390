import { Popover } from "@headlessui/react";
import { Gallery, MessageForm, Share } from "@launerlondon/shop-components";
import { useMetaTags, usePost, usePosts } from "@launerlondon/shop-hooks";
import { Post } from "@launerlondon/shop-posts";
import { routes } from "@launerlondon/shop-router";
import { Link, Navigate, generatePath, useParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";

const RelatedArticles: React.FC<{ post: Post }> = ({ post }) => {
	const posts = usePosts()
		.filter((p) => !Object.is(post, p))
		.slice(0, 5);
	return (
		<aside className="sticky top-16 max-w-sm p-2">
			<h3 className="font-sans text-lg font-light">Recent articles</h3>
			<div className="divide-y">
				{posts.map((p) => (
					<Link
						key={p.slug}
						className="block"
						to={generatePath(routes.newsView, {
							id: p.id,
							"*": p.slug,
						})}
					>
						<article className="flex gap-4 py-4">
							<div
								className="h-[80px] w-[80px] shrink-0 rounded bg-gray-50 bg-cover"
								style={{
									backgroundImage: `url(${p.images[0]?.thumb.replace(
										".webp",
										".jpeg",
									)})`,
								}}
							/>
							<div>
								<h3 className="text-sm font-light">
									{p.title}
								</h3>
								<time
									className="text-xs"
									dateTime={p.date.toJSON()}
								>
									{p.date.toLocaleDateString()}
								</time>
							</div>
						</article>
					</Link>
				))}
			</div>
		</aside>
	);
};

function WinForm() {
	return (
		<aside className="mt-10 max-w-3xl border-t pt-10 text-sm">
			<h2 className="font-display text-lg">Win tickets</h2>
			<div>
				<p>
					All you need to do is enter your name, phone number and
					email details to be in the draw taking place on Wednesday
					30th April.
				</p>
				<Popover>
					<Popover.Button className="underline">
						Terms and Conditions apply.
					</Popover.Button>
					<Popover.Panel
						className="fixed inset-0 z-50 grid place-items-center"
						unmount={false}
					>
						{({ close }) => (
							<>
								<Popover.Overlay className="absolute inset-0 bg-black/50" />
								<section className="relative mx-4 my-20 max-h-[90vh] max-w-prose overflow-auto rounded bg-white p-8 shadow">
									<h3 className="text-xl font-semibold leading-tight">
										Win tickets to the Royal Windsor Horse
										Show Terms and Conditions
									</h3>
									<ol className="my-4 ml-4 list-decimal text-xs">
										{[
											"The promoter is: Launer London Limited and the competition is open to residents of the United Kingdom aged 18 years or over.",
											"The prize is two general entry tickets for Saturday 17th May to the Royal Windsor Horse Show which provides access to the show, but no allocated seating will be provided for this and no cash or other alternatives will be offered. The prizes are not transferable. Prizes are subject to availability and we reserve the right to substitute any prize with another of equivalent value without giving notice.",
											"Winners will be chosen on Wednesday 30th April at random by software, from all entries received and verified by Promoter and or its agents. Winners will be informed by no later than this date.",
											"Closing date for entry will be Tuesday 29th April at 5.00pm. After this date the no further entries to the competition will be permitted.",
											"There is no entry fee and no purchase necessary to enter this competition.",
											"By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.",
											"Entrants must apply to enter via the online form from the link provided to enter and can only enter once.",
											"Route to entry for the competition is via the competition entry form at launer.com - and full name, contact number and email address must be provided at the point of entry.",
											"Only one entry will be accepted per person. Multiple entries from the same person will be disqualified.",
											"No responsibility can be accepted for entries not received for whatever reason.",
											"The promoter reserves the right to cancel or amend the competition and these terms and conditions without notice in the event of any unforeseen circumstances, travel disturbances, or any actual, anticipated breach of any applicable law or regulation or any other event outside of the promoter's control.",
											"The promoter is not responsible for inaccurate prize details supplied to any entrant by any third party connected with this competition.",
											"The winner will be notified by email and if not applicable telephone. If the winner cannot be contacted or does not claim the prize within 24 hours of notification, we reserve the right to withdraw the prize from the winner and pick a replacement winner.",
											"The promoter will notify the winner when and where the prize can be collected/is delivered.",
											"By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.",
											"The competition and these terms and conditions will be governed by [English] law and any disputes will be subject to the exclusive jurisdiction of the courts of [England].",
											"The winner agrees to the use of his/her name and image in any publicity material, as well as their entry. Any personal data relating to the winner or any other entrants will be used solely in accordance with current [UK] data protection legislation and will not be disclosed to a third party without the entrant's prior consent.",
											"Entry into the competition will be deemed as acceptance of these terms and conditions.",
											"This promotion is in no way sponsored, endorsed, administered by, or associated with, Facebook, Twitter, TikTok, or any other Social Network. You are providing your information to [Launer London and not to any other party.",
										].map((t, i) => (
											<li key={i}>{t}</li>
										))}
									</ol>
									<button
										onClick={() => close()}
										className="underline"
									>
										Close
									</button>
								</section>
							</>
						)}
					</Popover.Panel>
				</Popover>
				<MessageForm
					action="win"
					fields={[
						{ title: "Full name", required: true },
						{
							title: "Email",
							type: "email",
							required: true,
						},
						{
							title: "Phone number",
							type: "tel",
							required: true,
						},
						{
							title: "I would like to sign up to receive regular newsletters from Launer",
							name: "newsletter",
							type: "checkbox",
						},
					]}
				/>
			</div>
		</aside>
	);
}

type Params = { id: string; "*"?: string };
const NewsList: React.FC = () => {
	const { id } = useParams() as Params;
	const post = usePost(id);
	useMetaTags(
		{
			title: post?.title,
			path: generatePath(routes.newsView, {
				id: post?.id || "_",
				"*": post?.slug || "",
			}),
			image: post?.images[0]?.medium,
		},
		[post],
	);

	if (post === undefined) return null;
	if (post === null) return <Navigate to="/not-found" />;

	return (
		<div className="container my-10 flex flex-col items-start justify-between gap-4 md:flex-row">
			<article className="space-y-b">
				<h1 className="font-display max-w-3xl text-3xl font-light">
					{post.title}
				</h1>
				<time
					className="mb-4 block text-sm text-gray-400"
					dateTime={post.date.toJSON()}
				>
					{post.date.toLocaleDateString(undefined, {
						dateStyle: "full",
					})}
				</time>
				{post.text && (
					<div
						className="[&_a]:text-accent-500 max-w-3xl space-y-2 text-sm leading-relaxed"
						dangerouslySetInnerHTML={{ __html: post.text }}
					/>
				)}
				{post.source && (
					<cite className="font-sans text-xs uppercase not-italic text-gray-400">
						{post.source}
					</cite>
				)}
				<div>
					<Gallery
						className="my-10 max-w-3xl"
						images={post.images}
						imageClassName={twJoin(
							"w-full bg-black object-top",
							post.imageFit === "cover"
								? "object-cover"
								: "object-contain",
						)}
					/>
				</div>
				{post.footer && (
					<div
						className={twJoin(
							"max-w-3xl py-10",
							"space-y-2 text-sm leading-relaxed",
							"[&_a]:text-accent-500",
							"[&>div]:space-y-2",
							"[&_h4]:font-medium",
						)}
						dangerouslySetInnerHTML={{ __html: post.footer }}
					/>
				)}
				<Share
					className="mt-10 font-sans text-sm"
					title="Share this article"
				/>
				{id === "2025031401" &&
					new Date() <= new Date("2025-04-29T17:01:00.000Z") && (
						<WinForm />
					)}
			</article>
			<RelatedArticles post={post} />
		</div>
	);
};

export default NewsList;
