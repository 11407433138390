export function currencyToLang(code: string) {
	const langs: Record<string, string> = {
		AED: "en-AE",
		AUD: "en-AU",
		BRL: "pt-BR",
		CAD: "en-CA",
		CHF: "de-CH",
		CNY: "zh-CN",
		DKK: "da-DK",
		EUR: "de-DE",
		GBP: "en-GB",
		HKD: "zh-HK",
		NOK: "no-NO",
		NZD: "en-NZ",
		SEK: "sv-SE",
		SGD: "zh-SG",
		USD: "en-US",
	};
	return langs[code];
}

export function fmtCurrency(
	value: number,
	currency: string,
	currencyDisplay: "code" | "symbol" = "symbol",
) {
	return value.toLocaleString(currencyToLang(currency), {
		style: "currency",
		currency,
		currencyDisplay,
	});
}

export function getSocialUrl(
	provider: "facebook" | "twitter" | "pinterest" | "instagram",
) {
	return {
		instagram: "https://www.instagram.com/launerlondonofficial",
		pinterest: "https://www.pinterest.com/launerlondon",
		facebook: "https://www.facebook.com/launerlondonofficial",
		twitter: "https://x.com/launerlondon",
	}[provider];
}

export function getShareUrl(
	provider: "facebook" | "twitter" | "pinterest" | "whatsapp",
	url: string,
	text?: string,
	img?: string,
) {
	const $url = encodeURI(url);
	const $text = encodeURI(text || "");
	const $img = encodeURI(img || "");
	return {
		facebook: {
			url: `//facebook.com/sharer/sharer.php?u=${$url}&ret=login&display=popup`,
			width: 555,
			height: 500,
		},
		twitter: {
			url: `//twitter.com/intent/tweet?text=${$text}&url=${$url}`,
			width: 400,
			height: 230,
		},
		pinterest: {
			url: `//pinterest.com/pin/create/button/?url=${$url}&description=${$text}&media=${$img}`,
			width: 750,
			height: 750,
		},
		whatsapp: {
			url: `https://api.whatsapp.com/send/?phone&text=${$url}&app_absent=0`,
			//url: `whatsapp://send?text=${url}`,
			width: 0,
			height: 0,
		},
	}[provider];
}

export function getGridDividerIndex(
	itemsLength: number,
	cols: number,
	splitIn = 2,
) {
	const half = Math.ceil(itemsLength / splitIn);
	const diff = half % cols;
	return half - diff;
}
