import { useCallback, useEffect, useState } from "react";

type Size = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export default function useBreakpoint() {
	const [breakpoint, setBreakpoint] = useState<Size>("xs");

	const onResize = useCallback(() => {
		const w = window.innerWidth;
		let o: Size = "xs";
		if (w > 640) o = "sm";
		if (w > 768) o = "md";
		if (w > 1024) o = "lg";
		if (w > 1280) o = "xl";
		if (w > 1536) o = "2xl";
		setBreakpoint(o);
	}, []);

	useEffect(() => {
		onResize();
		addEventListener("resize", onResize);
		return () => removeEventListener("resize", onResize);
	}, []);

	return breakpoint;
}
