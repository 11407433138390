import { Localized, useLocalization } from "@launerlondon/l10n";
import { fmtCartItems } from "@launerlondon/products";
import type { CartItem as CartItemData } from "@launerlondon/products/src/lib/cartItem";
import type {
	Customer,
	Item,
	ItemOption,
	Language,
	RetailOrder,
	Event as RetailOrderEvent,
	RetailOrderRaw,
} from "@launerlondon/shared";
import {
	Total,
	VoucherRef,
	apiRequest,
	defaultRoundFactor,
	getOrderTotal,
	hasVendorPayment,
	validateOrder,
} from "@launerlondon/shared";
import { Modal } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { CheckoutLoaderData } from "@launerlondon/shop-router";
import type {
	AddressData,
	CheckoutData,
	RootState,
} from "@launerlondon/shop-types";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import cx from "classnames";
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { useLoaderData } from "react-router-dom";
import {
	CartCheckout,
	CartConfirmation,
	CartItem,
	CartPaymentRedirect,
	CartSummary,
} from "../components";
import CartShare from "../components/CartShare";
import Disclaimer from "../components/Disclaimer";
import loadStripe, { requestPayment } from "../lib/stripe";
import { flags, hasOne } from "../lib/utils";
import { setPaymentMethod } from "../redux/actions";

type APIHeartbeat = {
	paymentType?: string;
	event?: RetailOrderEvent;
	error?: string;
};

const fmtOrder = async (
	checkoutData: CheckoutData,
	items: CartItemData[],
	displayCurrency: string,
	billingCurrency: string,
	displayLanguage: string,
	vouchers: VoucherRef[],
): Promise<RetailOrderRaw | undefined> => {
	const checkout = checkoutData as CheckoutData;
	const address = { ...checkout.shippingAddress } as AddressData;

	const fmtItems = items.map<Item>((i) => {
		return {
			sku: i.sku,
			name: i.name,
			price: i.price,
			options: i.options.map<ItemOption>((o) => ({
				name: o.label,
				value: o.value,
				multiplier: o.multiplier,
				default: o.default,
			})),
		};
	});

	setPaymentMethod;
	const customer: Customer = {
		name: (checkout.name || "").replace(/\s{2,}/g, " ").trim(),
		email: checkout.email || "",
		phone: checkout.tel || "",
		language: displayLanguage as Language,
		currency: displayCurrency.toLowerCase() as "gbp",
		referrer: checkout.referrer || undefined,
		address: {
			line1: address?.line1 || "",
			city: address?.city || "",
			country: address?.country || "",
			state: address?.state,
			postal_code: address?.postal_code || "",
		},
	};

	const payment = {
		type: checkout.payment?.type || "",
		currency: billingCurrency.toLowerCase(),
	} as RetailOrder["payment"];

	if (hasOne(fmtItems)) {
		return {
			customer,
			payment,
			items: fmtItems,
			notes: checkout.notes || undefined,
			vouchers,
		};
	}
	return;
};

const Cart: React.FC = () => {
	const { vouchers } = useLoaderData() as CheckoutLoaderData;
	const cartItemsSnap = useSelector((s: RootState) => s.cart);
	const [items, setItems] = useState<CartItemData[]>([]);
	const locale = useSelector((s: RootState) => s.locale);
	const stripe = useStripe();

	useMetaTags({ title: "Checkout" });

	useEffect(() => {
		fmtCartItems(
			locale.currency,
			locale.country,
			vouchers.map((v) => v.currency),
			cartItemsSnap,
		).then(setItems);
	}, [locale.currency, locale.country, cartItemsSnap, vouchers]);

	const { l10n } = useLocalization();
	const checkoutRef = useRef<CartCheckout>(null);
	const cartTotalsRef = useRef<HTMLDivElement>(null);

	const [status, setStatus] = useState<
		"view" | "checkout" | "pending" | "polling" | "confirm"
	>("view");
	const [order, setOrder] = useState<RetailOrderRaw>();
	const [orderId, setOrderId] = useState<string>();
	const [orderRef, setOrderRef] = useState<number>();
	const [actionEvent, setActionEvent] = useState<RetailOrderEvent>();
	const [paymentError, setPaymentError] = useState<string>();
	const [checkoutData, setCheckoutData] = useState<CheckoutData>({});
	const [total, setTotal] = useState<Total>();

	useEffect(() => {
		fmtOrder(
			checkoutData,
			items,
			locale.currency,
			locale.billingCurrency,
			locale.lang,
			vouchers,
		).then((o) => {
			if (!o) return;
			setOrder(o);
			setTotal(getOrderTotal(o, defaultRoundFactor));
		});
	}, [
		checkoutData,
		items,
		locale.currency,
		locale.billingCurrency,
		locale.lang,
		vouchers,
	]);

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>;

		async function pollOrder() {
			const r = await apiRequest
				.get<APIHeartbeat>(`/api/orders?id=${orderId}`, {})
				.catch((err) => err.response);
			const { paymentType, event, error } = r.data;

			if (event?.type === "pending" && event.url) {
				setActionEvent(event);
				timeout = setTimeout(pollOrder, 2000);
			}
			if (
				event?.type === "charged" ||
				(event?.type === "placed" && paymentType === "offline")
			) {
				setStatus("confirm");
			}
			if (event?.type === "failed" || error) {
				setActionEvent(undefined);
				setStatus("checkout");
				setPaymentError(event?.message || error);
			}
		}

		if (orderId && status === "polling") {
			pollOrder();
		}
		return () => clearTimeout(timeout);
	}, [orderId, status, l10n]);

	useLayoutEffect(() => {
		if (status === "checkout") {
			const top = (checkoutRef.current?.getOffsetTop() || 0) - 220;
			scrollTo({ top });
		}
		if (status === "pending" || status === "polling") {
			cartTotalsRef.current?.scrollIntoView({ block: "center" });
		}
	}, [status]);

	const onSubmit = useCallback(async () => {
		if (status === "view") {
			return setStatus("checkout");
		}
		if (order && checkoutRef.current?.validate()) {
			try {
				validateOrder(order);
			} catch (err: any) {
				return setPaymentError(err.message);
			}
			setStatus("pending");
			let paymentMethodId: string | undefined;

			if (checkoutData.payment?.type === "card") {
				paymentMethodId = checkoutData.payment.card?.id;
			}

			if (stripe && hasVendorPayment(order)) {
				paymentMethodId = await requestPayment(stripe, order, {
					onCancel: () => setStatus("checkout"),
				});
			}

			const body = {
				order,
				orderId: flags.checkoutTest ? "0000" : orderId,
				paymentMethodId,
			};

			const r = await apiRequest
				.post("/api/orders", body)
				.catch((err) => {
					const message = err.response.data.error;
					setPaymentError(message);
					setStatus("checkout");
					throw new Error(message);
				});
			setOrderId(r.data.orderId);
			setOrderRef(r.data.orderRef);
			setStatus("polling");
		}
	}, [stripe, status, order, orderRef, l10n]);

	if (status === "confirm" && orderRef !== undefined) {
		return <CartConfirmation order={order} orderRef={orderRef} />;
	}

	if (items.length === 0 || !order) {
		return (
			<div className="my-20 space-y-4 text-center">
				<h3 className="ln-subtitle m-auto max-w-sm">
					<Localized id="cart-empty-title" />
				</h3>
				<p className="text-sm">
					<Localized id="cart-empty-text" />
				</p>
			</div>
		);
	}

	return (
		<div className="container mb-32 flex w-full flex-col items-start justify-between py-1 lg:flex-row">
			{paymentError && (
				<Modal
					className="max-w-[400px]"
					title={l10n.getString("payment-error-title")}
					onCancel={() => setPaymentError(undefined)}
				>
					<div className="text-center text-sm leading-relaxed">
						<p>{l10n.getString(paymentError)}</p>
					</div>
				</Modal>
			)}
			{actionEvent && (
				<CartPaymentRedirect
					type={order.payment.type}
					event={actionEvent}
					onCancel={() => {
						setStatus("checkout");
						setActionEvent(undefined);
					}}
				/>
			)}
			<div className="w-full lg:w-3/5 lg:p-4">
				<h2 className="ln-subtitle mt-4 border-b pb-1">
					{l10n.getString(
						status === "view"
							? "cart-items-title"
							: "cart-checkout-title",
					)}
				</h2>
				{status === "view" ? (
					<Disclaimer
						className="rounded-sm"
						text="basket-disclaimer"
					/>
				) : (
					<Disclaimer
						className="rounded-sm"
						text="checkout-disclaimer"
						url="checkout-disclaimer-link"
					/>
				)}

				{status === "view" ? (
					<div className="flex flex-col divide-y divide-dotted">
						{items.map((i) => (
							<CartItem key={i.id} item={i} />
						))}
					</div>
				) : (
					<CartCheckout
						className={cx(
							"transition-opacity",
							status !== "checkout" &&
								"pointer-events-none opacity-50",
						)}
						ref={checkoutRef}
						total={total}
						onChange={setCheckoutData}
					/>
				)}
			</div>
			<div
				ref={cartTotalsRef}
				className="sticky top-9 w-full p-1 lg:w-1/3 lg:p-4"
			>
				<CartSummary
					order={order}
					checkoutStatus={status}
					onEdit={() => {
						setStatus("view");
						scrollTo(0, 0);
					}}
					onSubmit={onSubmit}
					testMode={flags.checkoutTest}
				/>
			</div>
		</div>
	);
};

const CartWrapper: React.FC = () => {
	return (
		<CartShare>
			<Elements stripe={loadStripe()}>
				<Cart />
			</Elements>
		</CartShare>
	);
};

export default CartWrapper;
