import { useMetaTags } from "@launerlondon/shop-hooks";

const Returns: React.FC = () => {
	useMetaTags({
		title: "Returns and refunds",
		description:
			"If for any reason the product does not meet your expectations, please return it within 15 days and we will arrange for an immediate exchange or refund.",
	});
	return (
		<div>
			<div className="container">
				<header className="ln-intro-grid">
					<h2 className="font-display">Returns and Refunds</h2>
					<div>
						<p>
							We take pride in the quality of our products, and
							our policy is to ensure that all products supplied
							are delivered in perfect condition. If for any
							reason the product does not meet your expectations,
							please return it by recorded delivery with proof of
							purchase within 15 days of receipt of the goods and
							we will arrange for an immediate exchange or refund.
						</p>
					</div>
				</header>
				<div className="ln-section-grid">
					<div className="divide-y pb-20">
						<article className="space-y-4 py-8" id="return-address">
							<h3 className="ln-subtitle">Return Address</h3>

							<p>
								Launer Online Ltd
								<br />
								Holtshill Lane
								<br />
								Walsall
								<br />
								WS1 2JA
								<br />
								Kingdom
							</p>

							<p>
								We regret that we are unable to offer refunds on
								items that have been personalised. This policy
								does not affect your statutory rights.
							</p>
							<p>
								In addition to your 15-day returns guarantee,
								under the Consumer Protection (Distance Selling)
								Regulations 2000, customers in the European
								Union are entitled to a right to cancel the
								contract within a period of 7 working days from
								the date you place your order. This applies to
								all of our products.
							</p>
							<p>
								Please note that you will be responsible for the
								costs of returning the goods to us.
							</p>
							<p>
								Measurements are approximate to the nearest half
								centimetre or quarter inch.
							</p>
						</article>
						<article className="space-y-4 py-8" id="refunds">
							<h3 className="ln-subtitle">Refunds</h3>

							<p>
								Personalised bespoke leather goods are made to
								order. We regret that we are unable to offer
								refunds or exchanges.
							</p>
							<p>
								However, if we have made an error in fulfilling
								your order, we shall promptly correct or replace
								the items supplied to you. This policy does not
								affect your statutory rights.
							</p>
							<p>Shipping fees are non-refundable.</p>
						</article>
						<article
							className="space-y-4 py-8"
							id="duty-and-customs"
						>
							<h3 className="ln-subtitle">
								Duty and Customs Information
							</h3>

							<p>
								All overseas deliveries are subject to local
								import duties and taxes and may be affected by
								Customs and Excise delays. Please note that
								Launer cannot be held liable for any local
								import duties and taxes.
							</p>
						</article>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Returns;
