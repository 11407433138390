import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { getMenuLocaleId, Localized } from "@launerlondon/l10n";
import cx from "classnames";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getMenuParentCategories, MenuItem, useMenu } from "../lib/menu";
import { twMerge } from "tailwind-merge";

function useParentItems() {
	const pathname = useLocation().pathname;
	const [parentItems, setParentItems] = useState<MenuItem[]>([]);
	useEffect(
		() => void getMenuParentCategories(pathname).then(setParentItems),
		[pathname],
	);
	return parentItems;
}

const Item: React.FC<{ item: MenuItem; className?: string; sub?: boolean }> = (
	props,
) => {
	const isActive = useParentItems().includes(props.item);
	const { children, url, title, desktop } = props.item;
	if (desktop === false) return null;
	if (!children) {
		return (
			<NavLink
				key={title}
				to={url || "#"}
				className={({ isActive }) =>
					cx("flex-auto", props.className, isActive && "text-accent")
				}
			>
				<Localized id={getMenuLocaleId(title)} children={title} />
			</NavLink>
		);
	}
	return (
		<div className="sub relative flex-auto">
			<NavLink
				to={url || "#"}
				className={cx(
					"flex items-center justify-between",
					"cursor-pointer",
					isActive && "text-accent",
					props.className,
				)}
			>
				<Localized id={getMenuLocaleId(title)}>{title}</Localized>
				{props.sub && <ChevronRightIcon className="h-3 w-3" />}
			</NavLink>
			<div
				className={cx(
					"invisible [:hover>&]:visible",
					"[.sub_.sub>&]:-top-[2px] [.sub_.sub>&]:left-full",
					"absolute -left-3 top-full w-full divide-y-2 divide-white",
					"min-w-[220px]",
					"border-t-2 border-gray-900 bg-white",
					"-translate-y-4 opacity-0 transition-[transform,opacity] duration-300",
					"[:hover>&]:translate-y-0 [:hover>&]:opacity-100",
				)}
			>
				{children
					.filter((c) => c.desktop !== false)
					.map((i) => (
						<Item
							key={i.title}
							item={i}
							sub={Boolean(i.children?.length)}
							className={twMerge(
								"block whitespace-nowrap p-3 tracking-wide",
								"bg-gray-50 text-sm transition-colors hover:bg-gray-100",
								i.title.match(/by style/i) && "bg-gray-100",
							)}
						/>
					))}
			</div>
		</div>
	);
};

const Menu: React.FC<{ className?: string }> = ({ className }) => {
	const items = useMenu().filter((m) => m.title !== "account");

	return (
		<nav
			className={cx(
				"flex flex-1 items-center justify-between",
				className,
			)}
		>
			{items.map((i) => (
				<Item
					key={i.title}
					item={i}
					className={cx(
						"block py-4 text-[11px] xl:text-xs",
						"uppercase tracking-widest",
					)}
				/>
			))}
		</nav>
	);
};

export default Menu;
