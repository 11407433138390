import { Products, Row } from "@launerlondon/shop-components";
import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";

export default function CollectionRadiateGreen() {
	useMetaTags({
		title: "Luxury Valentine's Day Gifts | Custom Bags | Launer London",
		description:
			"Shop luxury Valentine's Day gifts at Launer London. Find gifts for her such as personalised bags, cardholders & more.",
		image: "/images/collections/valentines-banner.webp",
	});
	return (
		<div className="text-center">
			<Row.WithImage
				image="/images/collections/valentines-banner.webp"
				className="bg-gradient-to-r from-[#BE4E51] to-[#FAB0B1] text-sm text-white"
				contentClassName="space-y-10 mb-4 grid place-items-center"
			>
				<h1 className="ln-title flex flex-col text-center text-4xl lg:max-w-[500px]">
					Inspired Valentine's Gifts for Him and Her
				</h1>
			</Row.WithImage>
			<Row.Single className="lg:py-10">
				<p>
					Celebrate romance with Launer’s handmade Valentine’s Day
					gifts, tokens of love crafted from the finest leather for
					treasured memories.
				</p>
			</Row.Single>
			<Products skus={["3050-33", "805-17", "3119-05", "938-05"]} />
			<Row.WithImage
				reverse
				image="/images/collections/valentines-encore.webp"
				className="bg-[#FBE5E6] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/2914-09/encore-icing-pink-powder-pink"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Encore</h2>
					<p className="text-sm">Icing Pink / Powder Pink</p>
				</header>
				<p className="my-10 [&_a]:underline">
					A peerless style with understated detailing that is the
					essence of irresistibility. The Encore has been garnering
					plaudits since it launched in 1996 and is from our Legacy
					Collection.
				</p>
				<Link
					to="/p/2914-09/encore-icing-pink-powder-pink"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["670-02", "3100-01", "882-05", "717-12"]} />
			<Row.WithImage
				image="/images/collections/valentines-lettering.webp"
				className="bg-[#FBE5E6] text-[#746C6C]"
				contentClassName="space-y-8"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Complimentary lettering</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Make your creation unique with embossing; the addition of
					initials on handbags and accessories, creating a treasured
					personal product, the hallmark of a prestige item.
				</p>
				<p>
					A traditional and specialised skill that is carried out by
					our experienced craftspeople and applied in a range of foil
					finishes; gold, silver or blind.
				</p>
				<Link
					to="/customise-at-launer"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["620-03", "686-14", "805-31", "746-01"]} />
			<Row.WithImage
				reverse
				image="/images/collections/valentines-royale.webp"
				className="bg-[#FBE5E6] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/p/2826-05/royale-dusky-rose-very-berry"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Royale</h2>
					<p className="text-sm">Dusky Rose / Very Berry</p>
				</header>
				<p className="my-10 [&_a]:underline">
					The Royale displays all the remarkable proportions and
					features associated with a Launer design. Truly, a modern
					handbag, yet timeless in its appeal and beauty with a regal
					aura. It was first introduced in 1992 and is part of our
					Legacy Collection.
				</p>
				<Link
					to="/p/2826-05/royale-dusky-rose-very-berry"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Products skus={["670-06", "3124-03", "3097-18", "496-01"]} />
			<Products skus={["726-02", "3051-06", "685-05", "232-06"]} />
			<Row.WithImage
				image="/images/collections/valentines-nocturne.webp"
				className="bg-[#FBE5E6] text-[#746C6C]"
				contentClassName="space-y-8"
				imageLink="/s/nocturne"
			>
				<header className="space-y-2">
					<h2 className="ln-subtitle">Nocturne</h2>
				</header>
				<p className="my-10 [&_a]:underline">
					Perfectly formed, the elegant curves of The Nocturne create
					a beautifully compact and stylish handbag. Whether carrying
					it by its complementary top handle or the adjustable and
					attachable shoulder strap, this is a bag that has had
					timeless appeal since 1998. From The Legacy Collection.
				</p>
				<Link
					to="/s/nocturne"
					className="!mt-20 inline-block text-sm uppercase tracking-widest hover:text-[#485111]"
				>
					Shop now
				</Link>
			</Row.WithImage>
			<Row.Single
				className="text-sm text-[#746C6C] lg:py-20"
				contentClassName="max-w-5xl text-left"
			>
				<h3 className="w-full font-medium">
					Exquisite designs, the definition of refined British
					heritage come in all shapes and sizes
				</h3>
				<p>
					She will surely appreciate a Valentine’s Day gift bag, look
					to our signature styles for inspiration such as The Encore,
					Traviata or Nocturne. Consider a beautiful purse, jewellery
					tray or overnight luggage bag when seeking Valentine’s gift
					day inspiration for a wife, girlfriend or partner. 
				</p>
				<p>
					He will appreciate a hand made wallet or card case featuring
					Launer’s renowned turned-edge leatherwork made to the
					highest standard to last for life’s journeys. The full
					collection offers defined accessories and indispensable
					items such as tablet folio case, key ring cases, passport
					covers, spectacle cases and weekend luggage bag – all are
					exceptional Valentine’s Day gifts for him that are timeless
					pieces.
				</p>
				<p>
					Make your creation unique with embossing; the addition of
					initials on handbags and accessories, creating a cherished
					personalised luxury gift, the hallmark of a prestige item.
					All items are delivered in a gift box with complimentary
					shipping on bags and luggage.
				</p>
			</Row.Single>
		</div>
	);
}
