import {
	Logo,
	NavByline,
	NavItemAccount,
	NavItemBookmarks,
	NavWidgetTel,
} from "@launerlondon/shop-components";
import { routes } from "@launerlondon/shop-router";
import { NavLink } from "react-router-dom";
import {
	Breadcrumb,
	CartWidget,
	LocaleSelectorWidget,
	Menu,
	MenuMobile,
} from "../components";
import SearchField from "../components/SearchField";
import { twJoin } from "tailwind-merge";

const Header: React.FC = () => {
	return (
		<>
			<NavLink to={routes.home} className="m-auto my-2 block">
				<Logo
					className={twJoin(
						"mt-1 lg:mb-4 lg:mt-6",
						"w-[100px] lg:w-[200px]",
						"h-[70px] lg:h-[100px]",
					)}
				/>
			</NavLink>
			<NavByline className="-z-10" />
			<div className="relative sticky top-0 z-40 mx-auto w-full lg:static">
				<div
					className={twJoin(
						"container flex flex-wrap items-center",
						"justify-between gap-4 py-2 lg:justify-end lg:gap-0 lg:py-0",
					)}
				>
					<NavWidgetTel className="absolute left-10 top-2" />
					<MenuMobile className="lg:hidden" />
					<Menu className="hidden lg:flex" />
					<div
						className={twJoin(
							"lg:fixed lg:top-2",
							"flex items-center gap-4",
							"rounded-full bg-white/90 p-2",
						)}
					>
						<LocaleSelectorWidget />
						<SearchField />
						<CartWidget />
						<NavItemBookmarks />
						<NavItemAccount className="hidden lg:block" />
					</div>
				</div>
			</div>
			<Breadcrumb />
		</>
	);
};

export default Header;
