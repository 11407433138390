import { useLocalization } from "@launerlondon/l10n";
import { filterProductsByCategories } from "@launerlondon/products";
import { fmtSku } from "@launerlondon/shared";
import {
	useConfig,
	useMetaTags,
	useProduct,
	useProducts,
} from "@launerlondon/shop-hooks";
import { AppLoaderData, routes } from "@launerlondon/shop-router";
import { useEffect, useMemo } from "react";
import {
	generatePath,
	Navigate,
	useParams,
	useRouteLoaderData,
} from "react-router-dom";
import {
	ProductItem,
	ProductItemPlaceholder,
	ProductListSection,
} from "../components";
import { trackViewItem } from "../lib/analytics";
import addProductSchema from "@launerlondon/shop-utils/src/productSchema";

type Params = { sku: string; "*"?: string };

const ProductView: React.FC = () => {
	const { sku } = useParams() as Params;
	const { l10n } = useLocalization();
	const skus = useConfig();

	const { loggedIn } = useRouteLoaderData(routes.home) as AppLoaderData;
	const skuGroup = fmtSku(sku).group;
	const product = useProduct(sku);
	const products = useProducts();

	const related = useMemo(() => {
		if (!product || !products) return [];
		const filtered =
			filterProductsByCategories(
				products.filter((p) => p.sku !== sku && !p.private),
				[product.category || ""],
			)[0]?.items || [];
		if (skus.handbagsWithStraps.includes(skuGroup)) {
			return products
				.filter((p) => ["010-01", "011-01"].includes(p.sku))
				.concat(filtered);
		}
		if (skus.handbags.includes(skuGroup)) {
			return products
				.filter((p) => ["011-01"].includes(p.sku))
				.concat(filtered);
		}
		return filtered;
	}, [skuGroup, product, products]);

	useEffect(() => {
		if (!product) return;
		const flush = addProductSchema(product);
		return flush;
	}, [product]);

	useMetaTags(
		{
			title: `${product?.name} | ${l10n.getString(
				`menu-${product?.category}`,
			)}`,
			description: (product?.excerpt || product?.description)?.replace(
				/\n+/g,
				" ",
			),
			image: product?.images.product?.large,
			path: generatePath(routes.productView, {
				sku: product?.sku || "0000-00",
				"*": product?.slug || "",
			}),
		},
		[product, l10n],
	);

	useEffect(() => void (product && trackViewItem(product)), [product]);

	if (!loggedIn && product?.private) {
		return <Navigate to={routes.home} />;
	}

	if (product === null) return <Navigate to="/not-found" replace />;

	return (
		<>
			{product === undefined && <ProductItemPlaceholder />}
			{product && <ProductItem product={product} />}
			<div className="container relative mb-20">
				<ProductListSection
					title={l10n.getString("product-recommend-title")}
					headerClassName="text-base"
					products={related}
				/>
			</div>
		</>
	);
};

export default ProductView;
