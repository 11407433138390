import { Transition } from "@headlessui/react";
import {
	Bars3Icon,
	ChevronLeftIcon,
	XCircleIcon,
} from "@heroicons/react/24/outline";
import { Localized, getMenuLocaleId } from "@launerlondon/l10n";
import { isLoggedIn, routes } from "@launerlondon/shop-router";
import cx from "classnames";
import { useLayoutEffect, useMemo, useState } from "react";
import { NavLink, useFetcher } from "react-router-dom";
import { MenuItem, useMenu } from "../lib/menu";

const MenuMobile: React.FC<{ className?: string }> = ({ className }) => {
	const loggedIn = isLoggedIn();
	const items = useMenu();
	const [expanded, setExpanded] = useState(false);
	const [paths, setPaths] = useState<string[]>([]);
	const { submit } = useFetcher();

	const selected = useMemo(() => {
		if (paths.length === 0) return items;
		return paths.reduce<MenuItem[]>((p, c) => {
			const active = p.find((i) => i.title === c);
			return active?.children ? active.children : p;
		}, items);
	}, [items, paths]);

	useLayoutEffect(() => {
		const html = document.documentElement;
		html.style.overflowY = expanded ? "hidden" : "auto";
		// reset nav depth when menu is hidden
		if (!expanded) setPaths([]);
	}, [expanded]);

	return (
		<div className={className}>
			<div className="rounded-full bg-white p-2">
				<Bars3Icon
					className="h-5 w-5 cursor-pointer"
					onClick={() => setExpanded(true)}
				/>
			</div>
			<Transition
				as="nav"
				show={expanded}
				className={cx(
					"fixed inset-0 overflow-auto",
					"flex flex-col items-center justify-center",
					"z-40 m-auto p-4",
					"font-sans text-sm uppercase tracking-wider",
					"text-center text-gray-500",
				)}
			>
				<Transition.Child
					className="absolute inset-0 origin-top-left bg-white transition-all duration-200"
					enterFrom="opacity-0 translate-x-0 translate-y-0 scale-x-[10%] scale-y-[5%] rounded-r-full"
					leaveTo="opacity-0 translate-x-0 translate-y-0 scale-x-[10%] scale-y-[5%] rounded-r-full delay-300"
				/>
				<Transition.Child
					className="inset-x-50 absolute inset-x-auto bottom-4 cursor-pointer transition-[transform,opacity] duration-500"
					enterFrom="-translate-y-6 opacity-0"
					leaveTo="-translate-y-6 opacity-0"
				>
					<XCircleIcon
						className="h-10 w-10"
						strokeWidth={1}
						onClick={() => setExpanded(false)}
					/>
				</Transition.Child>
				{paths[0] && (
					<Transition.Child
						as="h4"
						className={cx(
							"relative flex cursor-pointer justify-center",
							"w-full items-center gap-2 py-3 font-semibold",
							"transition-[transform,opacity] duration-500",
						)}
						enterFrom="-translate-x-6 opacity-0"
						leaveTo="translate-x-6 opacity-0"
						onClick={() => setPaths((p) => p.slice(0, -1))}
					>
						<ChevronLeftIcon className="-ml-6 h-4 w-4" />
						<Localized
							id={getMenuLocaleId(paths[paths.length - 1])}
						/>
					</Transition.Child>
				)}
				{selected
					.filter((o) => {
						if (o.title === "account-logout") return loggedIn;
						return true;
					})
					.map(({ children, title, url }, idx) => {
						const key = paths.concat(title).toString();
						const child =
							children || !url ? (
								<span
									key={key}
									onClick={() =>
										setPaths((p) => [...p, title])
									}
								>
									<Localized
										id={getMenuLocaleId(title)}
										children={title}
									/>
								</span>
							) : (
								<NavLink
									key={key}
									to={url}
									rel={
										url.match("account")
											? "nofollow"
											: undefined
									}
									className="relative"
									onClick={() => {
										if (title === "account-logout") {
											submit(null, {
												method: "delete",
												action: routes.signIn,
											});
										}
										setExpanded(false);
									}}
								>
									<Localized
										id={getMenuLocaleId(title)}
										children={title}
									/>
								</NavLink>
							);
						return (
							<Transition.Child
								as="span"
								key={key}
								className="relative w-full cursor-pointer py-3 transition-[transform,opacity] duration-500"
								style={{
									transitionDelay: `${idx * 30 + 100}ms`,
								}}
								enterFrom="-translate-x-6 opacity-0"
								leaveTo="translate-x-6 opacity-0"
								children={child}
							/>
						);
					})}
			</Transition>
		</div>
	);
};

export default MenuMobile;
