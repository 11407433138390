import { useMetaTags } from "@launerlondon/shop-hooks";
import { Link } from "react-router-dom";
import { twJoin } from "tailwind-merge";

function Section(props: { links: string[]; title: string }) {
	return (
		<section id={props.title.toLowerCase().replace(/ /g, "-")}>
			<h2 className="ln-subtitle mt-10 border-b">{props.title}</h2>
			<ul
				className={twJoin(
					"my-4 grid",
					"gap-4 md:gap-2",
					"md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
				)}
			>
				{props.links.sort().map((k) => (
					<li key={k}>
						<Link to={k} className="hover:text-accent capitalize">
							{k.replace(/\/\w+\//, "").replace(/-+/g, " ")}
						</Link>
					</li>
				))}
			</ul>
		</section>
	);
}

export default function Sitemap() {
	useMetaTags({
		title: "Sitemap",
		description:
			"Find your favourite products all in one place. Our sitemap can help you explore our products quickly and easily.",
	});
	return (
		<div className="container my-10 text-center md:text-left">
			<h1 className="ln-title">Sitemap</h1>
			<Section
				title="Categories"
				links={[
					"/c/handbags",
					"/c/wallets",
					"/c/women",
					"/c/top-handle-bags",
					"/c/clutch-bags",
					"/c/shoulder-bags",
					"/c/day-bags",
					"/c/evening-bags",
					"/c/cross-body-bags",
					"/c/purses",
					"/c/women-card-holders",
					"/c/women-exotic",
					"/c/men",
					"/c/pocket-wallets",
					"/c/breast-pocket-wallets",
					"/c/men-card-holders",
					"/c/men-exotic",
					"/c/luggage",
					"/c/travel-bags",
					"/c/cabin-bags",
					"/c/portfolio-cases",
					"/c/wash-bags",
					"/c/luggage-accessories",
					"/c/accessories",
					"/c/eight-credit-card-wallet",
					"/c/gifts-for-her",
					"/c/gifts-for-him",
					"/c/christmas-gifts",
					"/c/luxury-stocking-fillers",
					"/c/gifts-under-250",
					"/c/tech-gifts",
					"/c/travel-gifts",
					"/c/wedding-bags",
					"/c/oversized-bags",
					"/c/mothers-day-gifts",
					"/c/spring-collection",
					"/c/mocha-tones",
					"/c/easter-gifts",
				]}
			/>
			<Section
				title="Collections"
				links={[
					//"/cc/alabaster",
					//"/cc/an-ode-to-autumn",
					"/cc/christmas",
					"/cc/christmas-accessories",
					"/cc/christmas-card-cases",
					"/cc/christmas-gift-ideas",
					"/cc/christmas-handbags",
					"/cc/christmas-purses",
					"/cc/christmas-wallets",
					"/cc/clever-colour-combinations",
					"/cc/coronation-collection",
					"/cc/fathers-day",
					//"/cc/hues-of-blue",
					//"/cc/idyllic-pinks",
					//"/cc/in-praise-of-pink",
					//"/cc/january-blues",
					"/cc/jubilee-collection",
					"/cc/legacy-collection",
					//"/cc/lilac-edit",
					//"/cc/men-greens-to-covet",
					"/cc/mothering-sunday",
					"/cc/orchid-enchants",
					"/cc/patent-wonder",
					//"/cc/peggy-porschen-launer",
					"/cc/queen-camilla",
					"/cc/range-rover-house",
					"/cc/royal-ascot",
					"/cc/royal-tartan",
					//"/cc/summer-2024",
					//"/cc/summer-brights",
					//"/cc/summer-whites",
					//"/cc/the-glossary",
					"/cc/the-hatherton-collection",
					//"/cc/the-ultimate-bags-for-that-special-occasion",
					"/cc/valentines-her",
					"/cc/valentines-him",
					"/cc/wimbledon-essentials",
					//"/cc/wisteria-lilac-edit",
					//"/cc/women-greens-to-covet",
					"/cc/leopard",
					"/cc/perfect-party-bags",
					"/cc/radiate-in-green-this-winter",
					"/cc/valentines-day",
				]}
			/>
			<Section
				title="Best Selling Styles"
				links={[
					"/s/bellini",
					"/s/encore",
					"/s/grey",
					"/s/iris",
					"/s/jubilee",
					"/s/judi",
					"/s/juliet",
					"/s/nocturne",
					"/s/royale",
					"/s/traviata",
					"/s/viola",
				]}
			/>
		</div>
	);
}
