import { useProductListParams, useProducts } from "@launerlondon/shop-hooks";
import { twJoin, twMerge } from "tailwind-merge";
import Row from "./Row";
import { Link } from "react-router-dom";
import ProductListItem from "./ProductListItem";

type Field = { title: string; description?: string };

type RowField = {
	rowOptions?: {
		reverse?: boolean;
		className?: string;
		imageClassName?: string;
	};
};
type LinkableField = { link?: { text: string; to: string } };

type Divider = Field & RowField & LinkableField;

type Data = Record<
	string,
	{
		header: Field & RowField;
		dividers?: Divider[];
		tiles?: Array<{ sku: string; image?: string } | string>;
		footer?: Array<Partial<Field>>;
	}
>;

const data: Data = {
	handbags: {
		header: {
			title: "Handbags",
			description:
				"Our women’s bags are a celebration of the finest in British heritage. Luxurious, timeless  pieces, crafted by hand in the heart of England in a listed Edwardian building for more than 80 years.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		dividers: [
			{
				title: "Customisation",
				description:
					"Every article in our collection, from handbags, to purses, wallets and accessories can be reimagined in a colour(s) and leather finish of your choice. It’s a luxury milestone, unique to Launer and handcrafted to our renowned impeccable standard. There is no additional cost for this bespoke service, should you opt for the same calf or patent leather finishes.",
			},
		],
		tiles: [
			"3050-01",
			"3050-28",
			"3124-11",
			"3085-01",
			"2914-10",
			"2875-04",
		],
		footer: [
			{
				title: "A handbag by Launer",
				description:
					"The beauty of a British made handbag from Launer lies in its superb craftsmanship and meticulous attention to detail; a leather handbag that endures over time retaining its beauty with a style that transcends trends.",
			},
			{
				title: "Customisation",
				description:
					"The term designer handbag is naturally attributed to our <a href='/c/top-handle-bags'>top handle<a/>, <a href='/c/cross-body-bags'>cross body</a>, <a href='/c/clutch-bags'>clutch</a> and evening styles but at Launer, they represent this and much more. Colour customisation is a service we have pioneered which enables customers to create a piece that is personal to them and can be applied to our luxury handbags as well as our women’s <a href='/c/purses'>purses</a>, <a href='/c/accessories'>leather accessories</a> or even our essential <a href='/c/women-card-holders'>ladies card holders</a>. We can add an additional flourish with gold foil lettering that is hand embossed on selected products.",
			},
			{
				title: "Quiet Luxury",
				description:
					"Launer is proud of its quiet luxury bag status, we make only to order, upholding a sustainable approach, eliminating waste. When you buy a Launer women’s leather bag, it comes with a lifetime warranty, it is neither discounted or sells at a reduced price which gives you complete assurance in our bags – a sign of excellence.",
			},
		],
	},
	"top-handle-bags": {
		header: {
			title: "Top Handle Bags",
			description:
				"Our designer top handle bags are an enduring symbol of ladylike elegance. They elevate any look into a stylish ensemble with a timeless sensibility.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		dividers: [
			{
				title: "Ultimate versatility",
				description:
					"Top handle handbags look every bit as refined when they are worn cross body with their complimentary attachable strap. Invest in your wardrobe with a beautiful leather top handle bag that will remain an essential for years to come.",
			},
		],
		tiles: [
			"3050-01",
			"3050-28",
			"3124-11",
			"3085-01",
			"2914-10",
			"2875-04",
		],
	},
	"clutch-bags": {
		header: {
			title: "Clutch Bags",
			description:
				"A designer clutch bag is the perfect accessory by day or night. Beautifully understated, it’s a wardrobe must-have and at Launer has been perfected. Every one of our leather clutch bags comes with an attachable chain or strap as well as a cosmetic mirror, ideal for on the go.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		tiles: ["2910-03"],
		footer: [
			{
				title: "A renaissance moment",
				description:
					"The luxury clutch is enjoying something of a renaissance at this time, often carried by Queen Camilla at events and state occasions. At Launer, we are the home of the elegant clutch bag with a host of styles to suit any occasion.",
			},
		],
	},
	"shoulder-bags": {
		header: {
			title: "Shoulder Bags",
			description:
				"The indispensable women’s shoulder bag is a must-have in any wardrobe, a beautifully versatile accessory by day or by night. At Launer, as you would expect, our leather shoulder bags are refined and supremely crafted by our skilled artisans in our owned factory using the finest Italian leather. Adopting many guises, they come in a variety of sizes and styles from micro to shopper with stunning elegance.</br><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		tiles: ["2910-07", "2875-04"],
		footer: [
			{
				title: "Deluxe day bags",
				description:
					"Our top handle bags all feature an attachable shoulder strap option and double handle styles are designed to also be carried by hand. From the ladylike poise of the Turandot to the casual luxe of Elizabeth, the signature house rope emblem is adorned along with a complimentary cosmetic mirror and protective dust bag making these the ultimate in designer shoulder bags.",
			},
			{
				title: "Sleek yet spacious",
				description:
					"For those seeking a small shoulder bag then Angelika or Emma are perfect whilst those wanting larger shoulder bags have Lydia and Sue to accommodate their needs. When you buy from Launer you are investing in a shoulder purse that will be part of your wardrobe for years to come.",
			},
		],
	},
	"day-bags": {
		header: {
			title: "Day bags",
			description:
				"Discover the epitome of elegance with our exquisitely detailed day bags. Handcrafted by a single skilled artisan from start to finish, each leather day bag embodies timeless sophistication and the Launer lauded hallmarks. Our meticulous designer bags offer both functionality and style, making them a refined companion for the most discerning when on-the-go.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		tiles: ["3085-01", "3087-07"],
		footer: [
			{
				title: "Elegance to suit the occasion",
				description:
					"Every designer day bag is fashioned from the finest leather, exclusive to Launer, ensuring a luxurious feel and enduring quality. Size options from large to micro are available, with ample interior space and therefore ideal for social and business needs.",
			},
			{
				title: "Add a beautiful matching accessory",
				description:
					"Embrace a blend of tradition and modernity with these stellar bags, a true testament to our heritage and innovation in British luxury leather craft.",
			},
		],
	},
	"evening-bags": {
		header: {
			title: "Evening Bags",
			description:
				"For those seeking the ultimate designer evening bag, Launer has a stunning array that are truly exquisite. Crafted in England by hand, our collection includes metallic <a href='/c/clutch-bags'>clutches</a>, patent <a href='/c/shoulder-bags'>shoulder bags</a> and elegant <a href='/c/top-handle-bags'>top handle</a> styles.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		dividers: [
			{
				title: "An item to treasure",
				description:
					"Our range is perfection: every piece is an iconic accessory that will ensure you make a stellar entrance to any event. Our women’s evening bag styles transcend time; a collectable item that goes beyond an everyday staple.",
				rowOptions: {
					reverse: true,
				},
			},
		],
	},
	"cross-body-bags": {
		header: {
			title: "Cross Body Bags",
			description:
				"The essential women’s cross body bag becomes an elevated style statement at Launer. Every one of our luxury leather cross body bags is delivered with a complimentary cosmetic mirror, adjustable strap and embossed leather plaque. A structured form also ensures this style retains a sophisticated air with a range of sizes to offer.<br/><br/>Many elements of our handbags can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "Unprecedented degree of workmanship",
				description:
					"Every piece is handcrafted in England by skilled artisans using traditional methods. Remember, our colour customisation service can transform your cross body purse into a unique timeless piece.",
			},
		],
	},
	purses: {
		header: {
			title: "Purses",
			description:
				"An exquisite handbag requires an exquisite purse to match. At Launer, we have a beautiful array of women’s purses which are handmade using traditional methods and luxury leather. Designed in different sizes to fit into our bags, our collection includes card cases alongside coin purses and pouches, all featuring our signature hallmarks.<br/><br/>Many elements of our purses can be customised; including colour, material and fittings.",
		},
	},
	"women-card-holders": {
		header: {
			title: "Women’s card holders",
			description:
				"The essential ladies card holder is a refined alternative to a purse, reassuringly handmade in England. Our leather card holders look stylish and elegant with the ability to store multiple cards and place discreetly in a Launer handbag or clothing pocket.<br/><br/>Many elements of our card holders can be customised; including colour and material.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, retaining a feminine appeal. The women’s credit card case makes an ideal gift for loved ones.",
			},
		],
	},
	"pocket-wallets": {
		header: {
			title: "Pocket Wallets",
			description:
				"The everyday men’s essential is an elevated affair with Launer’s distinguished pocket wallets, a renowned symbol of refined sophistication and impeccable craftsmanship. Each wallet is a testament to Launer's dedication to luxury, designed to seamlessly blend functionality with timeless elegance and feature the Launer trope of turned-edge leather work.<br/><br/>Many elements of our wallets can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "Flawlessly crafted by hand",
				description:
					"Our pocket wallets are meticulously crafted from the finest leather whilst the sleek design offers ample space for your essentials, featuring thoughtfully arranged compartments that keep your cards and currency organised with effortless style, yet is discreet when stored in pockets. Launer's commitment to excellence is evident in the intricate detailing and flawless finish of each wallet, handcrafted by a single skilled artisan from start to finish.",
			},
			{
				title: "Distinguished styles and sizes",
				description:
					"The understated elegance and superior quality make our pocket wallets an indispensable accessory for those seeking the ultimate in British hand made items. Experience the perfect harmony of form and function with Launer's pocket wallets, where classic design meets modern sophistication that endure for many years. For more <a href='/c/wallets'>wallet styles</a>, explore our <a href='/c/breast-pocket-wallets'>breast pocket wallets</a> and <a href='/c/men-card-holders'>cardholders</a>.",
			},
		],
	},
	"breast-pocket-wallets": {
		header: {
			title: "Breast Pocket Wallets",
			description:
				"Discover the finest handmade breast pocket wallets that Launer is acclaimed for, the ultimate accessory for the discerning gentleman. Every piece is made by a single skilled artisan in our atelier in England using our signature revered leather, symbols of our commitment to superior quality and traditional leather craft.<br/><br/>Many elements of our wallets can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "British heritage craftsmanship",
				description:
					"Each wallet is designed to fit seamlessly into your breast pocket in a discreet manner and convenient. The sleek profile and traditional craftsmanship uphold Launer’s heritage in a perfect blend of form and function. Ample space is provided for your essentials whilst maintaining the essence of understated luxury.",
			},
			{
				title: "An array of sizes and styles",
				description:
					"Choose Launer’s breast pocket wallets for a touch of classic refinement and unparalleled excellence in everyday accessories or explore our collection of sought after <a href='/c/pocket-wallets'>pocket wallets</a> and <a href='/c/men-card-holders'>card holders</a>.",
			},
		],
	},
	"men-card-holders": {
		header: {
			title: "Men’s card holders",
			description:
				"A must-have item for any discerning man has to be the cardholder wallet, a refined option reassuringly handmade in England. Our leather card holder looks stylish and elegant with the ability to store multiple cards and place discreetly in a jacket, coat or trouser pocket.<br/><br/>Many elements of our card holders can be customised; including colour and material.",
		},
		footer: [
			{
				title: "The perfect gift",
				description:
					"An indispensable, everyday accessory that is increasingly used, at Launer our designer cardholders are a distinguished affair. Making a considered gift; our luxury cardholder is presented in a gift box with a protective dust cover.",
			},
		],
	},
	wallets: {
		header: {
			title: "Wallets",
			description:
				"Our storied brand, as you would expect, boasts a distinguished array of men’s wallets. Often referred to as the finest designer wallets, our collection exudes an understated luxury. Every piece is handmade in England featuring our signature turned-edge leather work. There is a style to suit all men and their requirements including bifold designs, coin purses, classic pocket wallets and breast pocket wallets.<br/><br/>Many elements of our wallets can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "A considered gift",
				description:
					"A luxury British leather wallet has to be one of the most appreciated gifts and is a highly sought after purchase.",
			},
		],
	},
	luggage: {
		header: {
			title: "Luggage",
			description:
				"Launer London’s range of luxury luggage is designed with the discerning traveller in mind. Embark on your next journey across the globe with leather luggage handmade in Britain using only the finest materials of the highest quality.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Elevated travel pieces",
				description:
					"Find scotch grain bearing the hallmarks of Launer, synonymous with eight decades of handcrafted work. Our designer luggage collection features both men’s and women’s luggage sets. Find travel and cabin bags, the perfect companions for your next excursion.",
			},
			{
				title: "Discover more distinguished pieces",
				description:
					"Complete the set with <a href='/c/wash-bags'>wash bags</a> and <a href='/c/portfolio-cases'>leather portfolios</a>, and accessorise with <a href='/c/luggage-accessories'>luggage tags</a>, <a href='/s/passport-holder'>passport holders</a> and more. Let Launer London be the finest companion as you explore continents, coastlines and cultures.",
			},
		],
	},
	"travel-bags": {
		header: {
			title: "Travel Bags",
			description:
				"Let the journey begin with a sumptuous travel bag designed with tradition in mind for the modern-day traveller. Coming in three sizes, Launer London designer travel bags are the ideal weekend or overnight bag for men and women alike.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Handcrafted heritage luggage",
				description:
					"Take luggage to a sublime level with meticulous attention to detail, considered design and a refined engineered structure. Our luxury travel bags are crafted in a new enduring leather, scotch grain.",
			},
			{
				title: "Sublime luggage",
				description:
					"Whether you are a travel connoisseur looking for a man’s leather weekend bag, a lady’s overnight bag or a cabin bag, our handmade in Britain travel bags are the perfect companion: beautifully functional and crafted to last a lifetime.",
			},
		],
	},
	"cabin-bags": {
		header: {
			title: "Cabin Bags",
			description:
				"When boarding a flight to your next far-away destination, a Launer London luxury cabin bag is the perfect companion. There are options in the ideal hand luggage size and crafted in new enduring scotch grain leather, there is simply no better way to travel in style.  <br/> <br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "British handmade luggage",
				description:
					"Our cabin luggage bags are handmade in Britain with precise attention to detail and a refined engineered structure. Whether you are looking for a man’s cabin bag or a woman’s carry-on bag, we are certain to have the best accompaniment for your travels.",
			},
			{
				title: "Accessorise with Hatherton",
				description:
					"Complement your new leather luggage with a <a href='/c/wash-bags'>wash bag</a> or <a href='/c/luggage-accessories'>accessories</a> such as <a href='/c/luggage-accessories'>luggage tags</a> and <a href='/s/passport-holder'>passport holders</a>. If you are travelling for business, our leather folios may be just what you are seeking.",
			},
		],
	},
	"luggage-accessories": {
		header: {
			title: "Luggage accessories",
			description:
				"The discerning globetrotter requires considered luggage accessories to accompany them on their travels. A curated selection awaits with Launer London, from a designer passport holder to travel wallets and luggage tags, all crafted from the finest leather.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Hatherton Wash bag",
				description:
					"Designed to complement our travel and cabin bags, finish off your collection with a wash bag. Safely store your most valuable travel possessions in this spacious, luxurious item.",
			},
		],
	},
	"portfolio-cases": {
		header: {
			title: "Portfolio Cases",
			description:
				"The undisputed beauty of Launer’s leather craftsmanship is evident on our Hatherton portfolio cases. Demonstrating a timeless heritage that excels for travel or for business in our new enduring scotch grain in a choice of four ultimate colours.<br/><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		dividers: [
			{
				title: "Indispensable for everyday journeys",
				description:
					"If you seek to marry style, function, durability and a streamlined appearance then you have arrived at the right destination. Equally significant is the safe storage of your laptop, tablet and documents, and our leather folio cases have this mastered with an engraved padlock and key or reverse circular lock with padlock and key.",
				rowOptions: {
					reverse: true,
					imageClassName: "bg-contain bg-[#F2EEED]",
					className: "bg-[#F2EEED]",
				},
			},
		],
		footer: [
			{
				title: "Sizes to suit any excursion",
				description:
					"They come in many guises: small to extra large, zipped or the option of retractable handles on the laptop and tablet cases, which all bear the renowned hallmarks Launer is lauded for. True iconic pieces with a contemporary appeal, a leather portfolio is also ideal as a gift for him or her.",
			},
		],
	},
	"wash-bags": {
		header: {
			title: "Wash Bags",
			description:
				"Wash bags are a must-have for any frequent traveller, and are now reimagined in elegant style. Launer London's wash bag for men and women alike seamlessly blends style and function in a luxurious fashion.</br><br/>Many elements of our luggage can be customised; including colour and fittings.",
		},
		footer: [
			{
				title: "Store essentials",
				description:
					"Our toiletry bags are handcrafted in enduring scotch grain of the highest quality. For travels and overnight stays, it’s a must-have to store essentials with a spacious interior and water-resistant lining.",
			},
			{
				title: "A gift for the discerning",
				description:
					"Seeking that gift with a luxury touch? Our leather wash bags make a gift they will appreciate, whatever the occasion.",
			},
		],
	},
	accessories: {
		header: {
			title: "Accessories",
			description:
				"Indulge in the sophisticated elegance of Launer’s leather accessories, a curated collection of small leather goods that manifest the signature hallmarks of the Launer legacy. Each piece is a testament to Launer's dedication to unparalleled craftsmanship and luxurious design.<br/><br/>Many elements of our accessories can be customised; including colour, material and fittings.",
		},
		footer: [
			{
				title: "The sophisticated way to protect essentials",
				description:
					"Our passport holder, meticulously crafted from the finest leather, ensures your travel essentials are securely stylish. The leather phone case, tailored for a seamless fit, offers both protection and sophistication. For the discerning writer, our leather pen case provides an exquisite sanctuary for your finest instruments.",
			},
			{
				title: "Inspired luxury leather objects",
				description:
					"Valuables and personal items can now be organised with our elegantly designed leather tray. Elevate your everyday carry with our luxury key ring and leather key case, both exemplifying the perfect blend of functionality and opulence.",
			},
			{
				title: "A symbol of impeccable taste",
				description:
					"These accessories are more than just practical items; they are a statement of impeccable taste and sophistication. Choose Launer’s leather accessories to experience the pinnacle of luxury and enduring quality in every detail.",
			},
		],
	},
	"christmas-gifts": {
		header: {
			title: "Christmas Gifts",
			description:
				"The ultimate destination for extraordinarily beautiful leather gifts for him and her this Christmas, crafted with passion and expertise. Discover deluxe <a href='/c/luggage'>luggage</a>, legacy <a href='/c/handbags'>handbags</a>, heritage <a href='/c/wallets'>wallets</a> and a collection of impeccable small leather goods, handmade by our skilled artisans in our British atelier.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "A treasured keepsake",
				description:
					"A luxury Christmas gift from Launer is a demonstration of devotion to the finer things in life and an enduring keepsake through the years. Enhance your present further with our Customisation Service or explore Personalisation.",
			},
			{
				title: "Gift her a legacy handbag",
				description:
					"Seeking the best <a href='/c/gifts-for-her'>Christmas gift for ladies</a>? Our festive edit has been specially chosen with items such as the signature Traviata in faux embossed croc print or the ultimate cabin bag from The Hatherton Collection.",
			},
			{
				title: "Gift British heritage",
				description:
					"When you buy a luxury Christmas present from Launer, you are investing in a British legacy with an illustrious clientele that includes Royalty and so is sure to resonate. We have eminent wallets, travel bags, folios and a host of small leather goods that make the quest for the best <a href='/c/gifts-for-him'>Christmas gifts for men</a> easy.",
			},
		],
	},
	"luxury-stocking-fillers": {
		header: {
			title: "Luxury Stocking Fillers",
			description:
				"Elevate their gift experience this festive season with a handmade item from our luxury stocking fillers trove. They will cherish and appreciate Launer’s British heritage leather goods, created with devotion using traditional leather craft skills and featuring our renowned hallmarks.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "A heritage choice",
				description:
					"When seeking a present for him or for her, we have the most beautiful and refined gifts that can all be colour customised for that personal touch. Whether it’s a wife’s stocking filler or a girlfriend's stocking filler, they will love our micro handbags, engraved key fobs or mini purse and he will appreciate a card case or refined luggage tag.",
			},
			{
				title: "Elevate with personalisation",
				description:
					"Remember, we can personalise your present with lettering as well as presenting it in a stunning gift box. The best things come in small packages, so when shopping for those little stocking fillers, Launer has the solution with our heritage pieces that are pure exquisite luxury. For larger gifts, shop our full <a href='/cc/christmas'>Christmas presents</a> edit.",
			},
		],
	},
	"gifts-under-250": {
		header: {
			title: "Gifts Under £250",
			description:
				"Consider a gift they will truly covet with Launer’s British Heritage leather goods. We have a deluxe selection of gifts under £250 which come in a presentation branded box and can be colour customised, this ensures every piece is as special as the person you are gifting.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "A small gift says alot",
				description:
					"The choice is both considered, considerable and sure to impress with the Launer lauded hallmarks present; Engraved key fobs, spectacle cases, card holders, iPad cases, cosmetic mirrors, and card cases are amongst the choices all crafted by hand using our finest house leather. For all gifts, shop our full Christmas presents edit.",
			},
		],
	},
	"personalised-gifts": {
		header: {
			title: "Personalised & Customised Gifts",
			description:
				"Show how much you care this Christmas by having your gift for that special someone personalised with lettering – a name, initials or even a message transforms a present into a memorable keepsake which is sure to delight.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "The pinnacle of individuality",
				description:
					"A personalised gift is the perfect complement for our timeless heritage leather goods, from handbags to wallets, cardholders to travel bags and folio cases. For a truly elevated gifting experience discover our customisation service, any item can be ordered in any shade from our extensive colour portfolio.",
			},
			{
				title: "Luxurious options",
				description:
					"In addition, fittings and padlocks can be customised and gold-tipped edges added to wallets along with Faraday ® shielding. It shows true thought when you invest in a customised gift from Launer and will become a treasured memento. For all gifts, shop our full <a href='/cc/christmas'>Christmas presents</a> edit.",
			},
		],
	},
	"gifts-for-her": {
		header: {
			title: "Gifts for her",
			description:
				"Bring joy with Christmas gifts for her that she will treasure forever. Invest in one of our signature <a href='/c/handbags'>handbags</a>, a <a href='/c/top-handle-bags'>top handle</a>, <a href='/c/cross-body-bags'>cross body</a> or <a href='/c/clutch-bags'>clutch</a> are all stunning choices and demonstrate your regard when seeking that cherished Christmas gift for your wife or partner.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],

		footer: [
			{
				title: "Gift luxury luggage",
				description:
					"Discover our Hatherton Collection, the travel inspired <a href='/c/luggage'>luggage</a> range that is pure deluxe and makes the ideal luxury Christmas gift for her, the cabin bags are a timeless must-have and ideal for stay to day.",
			},
			{
				title: "A trove of treasures",
				description:
					"Consider a purse, it also makes a great gift choice and is such a beautifully crafted object made in the same traditional way as our bags. Take a look at engraved key fobs, card cases or a laptop case or tablet folio, they represent the best in British luxury leather goods.",
			},
			{
				title: "The finishing touch – personalisation",
				description:
					"The ultimate has to be a personalised Christmas gift for her with initials, a name or a personal message in gold foil which can be applied to your gift and expresses your love in a very special way. For all gifts, shop our full <a href='/cc/christmas'>Christmas presents</a> edit.",
			},
		],
	},
	"gifts-for-him": {
		header: {
			title: "Gifts for him",
			description:
				"You have arrived at the finest destination for luxury Christmas gifts for him, all crafted by hand by our skilled artisans. Discover our distinguished array of wallets, card cases and personal items all produced to a meticulous standard, and considered fine heritage keepsakes.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "Inspired heritage choice",
				description:
					"There is a style to suit any refined man and his requirements including the classic pocket wallet or bifold design or how about a breast pocket wallet? The dilemma of what to get him for Christmas will be easy with our curated men’s offer – an epic Grand Tour bag for his travel excursions or a laptop folio case from The Hatherton Collection; a superb wash bag, passport cover, key case or spectacles case.",
			},
			{
				title: "Colour customise and personalise",
				description:
					"Invest in Christmas gifts for him that are personalised and colour his present to the shade you know he loves, add his initials, name or a short message for the special touch. For all gifts, shop our full Christmas presents edit.",
			},
		],
	},
	"tech-gifts": {
		header: {
			title: "Gifts for Tech Lovers",
			description:
				"A Christmas tech gift is a must and requires traditional leather craft carriers to contain and protect them. With Launer’s timeless craftwork, we have the perfect tech gifts or should we say, receptacles that emit style and reassuring functionality.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "Maintains the pristine appeal",
				description:
					"Laptops, tablets and smartphones will retain their pristine appearance with the help of a Launer case and can be chosen in any colour and a range of leather finishes. A joyful Christmas gift that is ideal and shows how much you care.",
			},
			{
				title: "Must-have recepticles",
				description:
					"A tech gift for men has to be high on his wish list and a leather carrier to house them is a genius idea and one that can be crafted to suit his personality. We recommend you visit our Hatherton Collection, when seeking a tech gift for women with its exemplary range of folio cases for laptops and tablets which provides a masterful blend of style and function.",
			},
		],
	},
	"travel-gifts": {
		header: {
			title: "Gifts for Travel Lovers",
			description:
				"The destination for travel gifts with the discerning voyager in mind.<br/>From <a href='/c/luggage'>luggage</a> to <a href='/c/wash-bags'>wash bags</a>, passport covers to travel <a href='/c/wallets'>wallets</a>, key fobs and beyond, we have a carefully curated offer that conveys distinguished luxury and everyday practicality.",
		},
		dividers: [
			{
				title: "The Launer Gift Card",
				description:
					"Surprise someone special with a digital gift card from Launer. Select the amount in the currency of your choice and compose a personal message to the recipient, then allow Launer to take care of fulfilling their wishes.",
				link: { text: "Shop now", to: "/p/0001-01" },
				rowOptions: { imageClassName: "aspect-[4/2.5]" },
			},
		],
		footer: [
			{
				title: "Discover deluxe luggage",
				description:
					"With the introduction of <a href='/cc/the-hatherton-collection'>The Hatherton Collection</a>, our new scotch grain leather is ideal for those who travel for business or for pleasure with its remarkable appearance and robust ability. There is something to discover in our gifts for travel lovers collection that will elevate any journey.",
			},
		],
	},
	"wedding-bags": {
		header: {
			title: "Wedding Bags",
			description:
				"Discover Launer’s exquisite collection of wedding bags, the epitome of elegance for any sophisticated celebration of joining in matrimony. Explore our selection of wedding hand bags and bridal handbags, perfect for those seeking a statement of sophistication on the special day.",
		},
		footer: [
			{
				title: "Exquisitely Crafted",
				description:
					"Crafted from luxurious materials, our satin bags offer both style and functionality in a range of delicate shades. For the discerning, our wedding guest bag ensures you carry your essentials with unmatched sophistication. Choose Launer for wedding accessories that beautifully complement the grandeur of your celebration and help provide memories for years to come.",
			},
		],
	},
	judi: {
		header: {
			title: "Judi",
			description:
				"The Judi is a scaled down version of the iconic Traviata. Beautifully proportioned, this petite model is impeccably refined and carries our signature hallmarks of the twisted rope emblem, structured silhouette and top handle. Created in 1995 it is one of our most popular bags with a Royal seal of approval – The Queen Consort, Camilla often carries the Judi at functions and official engagements.",
		},
	},
	traviata: {
		header: {
			title: "Traviata",
			description:
				"The Traviata is a legend, acclaimed for its structured silhouette, exquisite, handcrafted detail and signature twisted rope emblem. First introduced in 1989, it achieved International acclaim as the handbag of choice for Her Late Majesty The Queen.",
		},
		tiles: ["3050-29", "3050-01"],
		dividers: [
			{
				title: "Since 1989",
				description:
					"This impeccable design transcends time remaining stylish and contemporary, the hallmark of a true investment piece.<br/><br/>It has been featured in films, illustrations, on cakes and in other guises resulting in its status as a style icon.",
			},
		],
	},
	juliet: {
		header: {
			title: "Juliet",
			description:
				"A very elegant, larger edition of the Traviata, with the same hero features exuding elegance and modernity. The Juliet masters daytime femininity with ease, enjoying a spacious interior and impeccable proportions. It sits with The Legacy Collection of heritage, iconic styles.",
		},
	},
	encore: {
		header: {
			title: "Encore",
			description:
				"A peerless style with understated detailing that is the essence of irresistibility. The Encore has been garnering plaudits since it launched in 1996 and is from our Legacy Collection.",
		},
	},
	iris: {
		header: {
			title: "Iris",
			description:
				"At 18.5cm in length, Iris is delightfully slight, displaying the coveted Launer hallmarks: structured silhouette, immaculate detail and signature twisted rope emblem, now enlarged. It's a style of immense appeal, introduced in celebration of our 80th anniversary. Timeless, stylish and an investment in British craftsmanship.",
		},
	},
	nocturne: {
		header: {
			title: "Nocturne",
			description:
				"Perfectly formed, the elegant curves of The Nocturne create a beautifully compact and stylish handbag. Whether carrying it by its complementary top handle or the adjustable and attachable shoulder strap, this is a bag that has had timeless appeal since 1998. From The Legacy Collection.",
		},
	},
	viola: {
		header: {
			title: "Viola",
			description:
				"The exquisite appeal of Viola lies in its petite form. A scaled down version of the iconic Traviata. Ultra luxurious, it's a style that captivates in an understated manner.",
		},
	},
	grey: {
		header: {
			title: "Grey Launer Styles",
			description:
				"Embrace all things grey, a shade that is enjoying a remarkable fashion renaissance. It adds a beautiful sense of serenity and style when crafted into our handmade bags and small leather goods with a hue to match any mood or occasion. Consider complementary tones such as Ash Grey and Misty Grey with a highlight colour as Traffic Red or Icing Pink for an individual touch or contrast with Dark Grey with the alabaster like Bone White.",
		},
	},
	"oversized-bags": {
		header: {
			title: "The season of the oversized bag",
			description:
				"The oversized tote bag has emerged as the key style to invest in this season. We have statement bags that are both capacious and stylish with a timeless essence that work tirelessly as your everyday companion.",
		},
		dividers: [
			{
				title: "Stylishly Versatile",
				description:
					"Designed with ease in mind, these large scale bags have double hands with a reassuring lightness, whilst retaining their structured shape.",
			},
		],
		footer: [
			{
				description:
					"This Spring it’s all about the women’s large handbag or investment items as we like to call them at Launer. These large bags display the signature Launer tropes of fine craftsmanship, rope emblem, exquisite detail, all made by hand in beautiful, supple leather.",
			},
			{
				description:
					"Our women’s large bags come in many guises: shoppers, day bags, shoulder bags and totes, perfect for on-the-go or vacation chic.",
			},
			{
				description:
					"Take a look at The Hatherton Collection with its peerless large scale folios,  lightweight contemporary options for men  which work equally as well when used as a large  purse for women in our new scotchgrain.",
			},
		],
	},
	"mothers-day-gifts": {
		header: {
			title: "Celebrate Mother’s Day with launer",
			description:
				"Celebrate Mothering Sunday with an extraordinary legacy gift, crafted from the most refined leather. Mum will love the experience of our British leather goods, every one a handmade love note representing the very best in mother's day gifts.",
		},
		dividers: [
			{
				rowOptions: { reverse: true },
				title: "Luxury Mother’s Day Gifts",
				description:
					"Discover luxury mother’s day gifts with an enduring quality that show how much you care. From divine handbags to beautiful purses and accessories presented in an elegant  box with complimentary shipping on all bags.<br/><br/>Allow them to appreciate the Launer experience, where every item is made by hand by skilled artisans with a passion for their craft.",
				link: {
					text: "More accessories",
					to: "/c/accessories",
				},
			},
		],
		footer: [
			{
				description:
					"Explore our luxurious selection of personalised mother’s day gifts. Monogramming is the ultimate personal touch when buying a present, applied by hand in fine gold foil, either a name, initials or a message. It enhances the beauty of our leather heirloom items and makes for a joy bringer of a gift. From mother’s to mother’s day gifts for daughters, Launer is the destination for objects of desire to be treasured through the years with a trove of considered options, all individually handcrafted.",
			},
		],
	},
	"spring-collection": {
		header: {
			title: "Inspiring new shades",
			description:
				"Calming shades of soft butter yellow and cool alabaster are the pillars of the colour palette combined with vibrant ciel blue. The result is an inspiring and refreshed take on our heritage bags to accompany you through Spring.",
			rowOptions: { className: "bg-[#FEFADA]" },
		},
		dividers: [
			{
				title: "All about Mocha",
				description:
					"Usher in warm tones of brown with the introduction of Mocha. A shade that lends itself to our exquisite bags in a remarkable way. From top handle to day bags, cross body to oversized styles, it’s a colour that is supremely stylish and contemporary.",
				link: {
					text: "View styles",
					to: "/c/mocha-tones",
				},
				rowOptions: {
					reverse: true,
					imageClassName: "bg-[#F9FAFB] aspect-[4/2.7]",
				},
			},
			{
				title: "Our Spring edit",
				description:
					"The oversized bag is key this season but the smaller sized styles are not to be overlooked. They remain the essential must-haves for events, occasions, race days and ceremonies. Two new designs,  Gabriella and Zerlina join the collection evoking effortless allure.",
				rowOptions: {
					imageClassName: "bg-[#F9FAFB] aspect-[4/2.7]",
				},
			},
		],
	},
	"mocha-tones": {
		header: {
			title: "All about Mocha",
			description:
				"Usher in warm tones of brown with the introduction of Mocha. A shade that lends itself to our exquisite bags in a remarkable way. From top handle to day bags, cross body to oversized styles, it’s a colour that is supremely stylish and contemporary.",
			rowOptions: { className: "text-white bg-[#857062]" },
		},
		dividers: [
			{
				title: "Accessories",
				description:
					"Usher in warm tones of brown with the introduction of Mocha. A shade that lends itself to our exquisite bags in a remarkable way. From top handle to day bags, cross body to oversized styles, it’s a colour that is supremely stylish and contemporary.",
			},
		],
	},
	"easter-gifts": {
		header: {
			title: "Luxury Easter gifts",
			description:
				"A time to celebrate all things Spring with inspiring, thoughtful handmade items which make beautiful presents or as a self treat. A new bag to add to your wardrobe or a piece of luggage for the traveller; an indispensable purse or men’s wallet: card holder, key ring or wash bag, these make for timeless Easter gifts that demonstrate how much you care.<br/><br/>At Launer, they come with the reassurance of tradition and leather craftsmanship steeped in heritage with a reputation for leather goods which endure.",
			rowOptions: { className: "bg-[#FCF5D7]" },
		},
	},
};

function useSection(section?: string) {
	const { search, slug, byStyle } = useProductListParams();
	const name = section || search || slug;
	return name && data[name] ? { ...data[name], name, byStyle } : undefined;
}

const Header: React.FC = () => {
	const section = useSection();
	if (!section?.header) return null;
	const opts = section.header.rowOptions;
	return (
		<div className={twMerge("bg-gray-50", opts?.className)}>
			<div className="container grid gap-4 max-lg:py-8 lg:grid-cols-2">
				<div className="grid items-center">
					<div className="space-y-2">
						<h1 className="ln-subtitle">
							{section.header.title}{" "}
							{section.byStyle && "by style"}
						</h1>
						{section.header.description && (
							<p
								className="text-sm lg:max-w-xl [&_a]:underline"
								dangerouslySetInnerHTML={{
									__html: String(section.header.description),
								}}
							/>
						)}
					</div>
				</div>
				<div
					className={twMerge(
						"aspect-[4/2] bg-cover bg-center max-lg:hidden",
						opts?.imageClassName,
						opts?.reverse && "-order-1",
					)}
					style={{
						backgroundImage: `url(/images/hero/${section.name}.webp)`,
					}}
				/>
			</div>
		</div>
	);
};

const Divider: React.FC<{ className?: string; index?: number }> = (props) => {
	const section = useSection();
	const index = props.index || 0;
	const divider = section?.dividers?.[index];
	if (!divider) return null;
	const opts = divider.rowOptions;
	const img = index
		? `${section.name}-divider-${index + 1}.webp`
		: `${section.name}-divider.webp`;
	return (
		<Row.WithImage
			reverse={opts?.reverse}
			className={twMerge(
				"overflow-hidden rounded-lg bg-gray-50 text-center",
				opts?.className,
				props.className,
			)}
			containerClassName="!px-0"
			imageClassName={twMerge("bg-cover bg-right", opts?.imageClassName)}
			contentClassName="max-w-lg"
			image={`/images/hero/${img}`}
		>
			<h3 className="text-lg font-[400]">{divider.title}</h3>
			<p
				className="text-sm [&_a]:underline"
				dangerouslySetInnerHTML={{
					__html: String(divider.description),
				}}
			/>
			{divider.link && (
				<Link
					to={divider.link.to}
					className="!mt-10 inline-block p-2 text-sm uppercase tracking-widest"
				>
					{divider.link.text}
				</Link>
			)}
		</Row.WithImage>
	);
};

const Footer: React.FC = () => {
	const section = useSection();
	if (!section?.footer) return null;

	return (
		<div className="border-t">
			<div className="container grid gap-8 py-10 lg:grid-cols-3">
				{section.footer?.map((s, idx) => (
					<div
						key={`footer-${idx}`}
						className={twJoin(
							"space-y-2",
							section.footer?.length === 1 && "col-span-2",
						)}
					>
						{s.title && <h4>{s.title}</h4>}
						<p
							className="text-xs text-gray-500 [&_a]:underline"
							dangerouslySetInnerHTML={{
								__html: String(s.description),
							}}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const BigTile: React.FC<{ section?: string; index?: number }> = (props) => {
	const { byStyle } = useProductListParams();
	const section = useSection(props.section);
	const tile =
		section?.tiles && props.index !== undefined
			? section.tiles[props.index]
			: undefined;
	if (!tile) return null;
	const sku = typeof tile === "string" ? tile : tile.sku;
	const product = useProducts([sku]).at(0);
	if (!product) return null;
	return (
		<ProductListItem
			product={product}
			imageQuality="large"
			showModelImage
			imageContainerClassName={twJoin(
				byStyle
					? "lg:aspect-[3/3.745] 2xl:aspect-[3/3.615]"
					: "lg:aspect-[3/3.9] 2xl:aspect-[3/3.74]",
			)}
		/>
	);
};

export default {
	Header,
	Divider,
	BigTile,
	Footer,
	keys: Object.keys(data),
	useSection,
};
