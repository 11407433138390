import { Product } from "@launerlondon/products";
export default function addProductSchema(product: Product) {
	const url = `https://launer.com/p/${product.sku}/${product.slug}`;
	const schema = {
		"@context": "http://schema.org/",
		"@type": "Product",
		brand: { "@type": "Brand", name: "Launer" },
		description: product.description,
		image: [
			product.images.product?.large,
			product.images.model?.large,
			...product.images.gallery.map((i) => i.large),
		].filter((img) => img !== undefined),
		name: product.name,
		offers: [
			{
				"@type": "Offer",
				availability: "http://schema.org/InStock",
				itemCondition: "http://schema.org/NewCondition",
				itemOffered: "Product",
				price: product.price.gbp,
				priceCurrency: "GBP",
				sku: product.sku,
				url,
			},
		],
		sku: product.sku,
		url,
		shippingDetails: {
			"@type": "OfferShippingDetails",
			shippingRate: {
				"@type": "MonetaryAmount",
				value: 9.5,
				currency: "GBP",
			},
			shippingDestination: {
				"@type": "DefinedRegion",
				addressCountry: "UK",
			},
			deliveryTime: {
				"@type": "ShippingDeliveryTime",
				handlingTime: {
					"@type": "QuantitativeValue",
					minValue: 7,
					maxValue: 21,
					unitCode: "DAY",
				},
				transitTime: {
					"@type": "QuantitativeValue",
					minValue: 1,
					maxValue: 10,
					unitCode: "DAY",
				},
			},
			freeShippingThreshold: {
				"@type": "MonetaryAmount",
				value: 1000,
				currency: "GBP",
			},
		},
		hasMerchantReturnPolicy: {
			"@type": "MerchantReturnPolicy",
			applicableCountry: "GB",
			returnPolicyCategory:
				"https://schema.org/MerchantReturnFiniteReturnWindow",
			merchantReturnDays: 15,
			returnMethod: ["https://schema.org/ReturnByMail"],
			returnShippingFeesAmount: {
				"@type": "MonetaryAmount",
				value: 15,
				currency: "GBP",
			},
		},
	};
	const script = document.createElement("script");
	script.id = "product-schema";
	script.type = "application/ld+json";
	script.innerText = JSON.stringify(schema);
	document.head.appendChild(script);

	return () => {
		document.head.querySelector("#product-schema")?.remove();
	};
}
